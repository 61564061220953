(function() {
	const chat4i = function() {
		'use strict'

		const chatStatuses = { open: 1, close: 2, success: 'success', disconnected: 'disconnected' };

		const mainPanel = document.getElementById('iwc-mainChatPanel');
		const contactChatBtn = document.querySelector('.iwc-contactPanel .iwc-openChat');
		

		var pickedUp = false, lastMessageFrom = '';
		var lastTypeTime, waitTimer = [];
		var lastHeartbeat, heartbeatTimer = [];

		var agentTyping = false;
		var agentTypingTimeout = {};

		var chatState = {
			conversationId: '',
			memberId: '',
			agentName: '',
			agentMemberId: '',
			customerName: '',
			chatHistory: [],
			status: chatStatuses.close,
			time: null,
			top: '',
			left: '',
			inPopout: false
		}

		if (!contactChatBtn) {
			window.addEventListener('beforeunload', function(e) {
				const section = document.getElementById('iwc-chatSection');
				if (section && !section.classList.contains('hide')) {
					// Cancel the event as stated by the standard.
					e.preventDefault();
					// Older browsers supported custom message
					e.returnValue = '';
				}
			});

			window.addEventListener('unload', function(e) {
				const section = document.getElementById('iwc-chatSection');
				section && !section.classList.contains('hide') && exitChat(true);
			});
		}

		// Saves/loads chatState to/from session for use on reloads and reconnects
		const chatStorage = (function() {
			const save = function() {
				chatState.time = new Date();
				sessionStorage.setItem("chatState", JSON.stringify(chatState));
			}

			const load = function() {
				try {
					return JSON.parse(sessionStorage.getItem("chatState"));
				} catch (err) {
					return null;
				}
			}

			return {
				Save: save,
				Load: load
			}
		})();
		
		// Setup
		const init = function() {
			if (chatStorage.Load()) {
				const state = chatStorage.Load();
				// if chat state exists and chat was open, reload/reconnect
				if (state.status === chatStatuses.open && (new Date() - new Date(state.time)) <= 120000) {
					chatState = state;
					reloadState();
					return;
				} else {
					if (state.inPopout && document.getElementById('iwc-mainChatPanel')) {
						document.getElementById('iwc-mainChatPanel').style.height = 'auto';
					}
				}
			}

			const section = document.getElementById('iwc-startSection');
			dragElement.Bind(section.getElementsByClassName('iwc-main-panel-header')[0]);

			document.getElementById('iwc-exitStartButton').addEventListener('click', function(e) {
				e.preventDefault();
				closeChat();
			});

			if (document.getElementById("iwc-popout-chat-start") && window.innerWidth >= 600) {
				document.getElementById("iwc-popout-chat-start").focus();
			} else {
				document.getElementById('iwc-exitStartButton').focus();
			}
			
			const btn = document.getElementById('iwc-startChat');

			// if office is closed button won't exist
			if (!btn) {
				return;
			}

			btn.disabled = false;
			btn.classList.remove('disabledBtn');
			
			btn.addEventListener('click', validateName);

			document.querySelector('#iwc-inputName').addEventListener("keydown", function(e) {
				if (e.key == "Enter") {
					e.preventDefault()
					btn.click();
				}
			});
			
			// check if in chat pop-out window
			if (contactChatBtn) {
				document.getElementById('iwc-popout-chat-start').addEventListener('click', function(e) {
					e.preventDefault();
					displayChatPopout(e);
				});
			} else {
				document.getElementById('iwc-popout-chat-start').parentNode.removeChild(document.getElementById('iwc-popout-chat-start'));
			}

			ajaxActions.GetWaitTime();
		}

		const validateName = (e) => {
			e.preventDefault();
			forms4i().formValidation(document.querySelector('#iwc-startSection'), onValidationSuccess, e, onValidationFailure);
		}

		const onValidationSuccess = () => {
		//console.log("success");
			document.getElementById('iwc-startSection').classList.add('hide');
			initChat();
		}
		
		const onValidationFailure = () => {
		//console.log("failed");
			document.querySelector('#iwc-inputName').removeEventListener("click", validateName);
			document.querySelector('#iwc-inputName').addEventListener('click', validateName);
			document.querySelector('#iwc-inputName').addEventListener("keydown", function(e) {
				if (e.key == "Enter") {
					e.preventDefault()
					document.querySelector('#iwc-inputName').click();
				}
			});
		}

		const getChatRequest = () => {
			const orgId = document.getElementById('iwc-OrganizationId').value;
			const deploymentId = document.getElementById('iwc_DeploymentId').value;
			const chatQueue = document.getElementById('iwc_ChatWorkgroup').value;

			const contactId = parseInt(document.getElementById('iwc_CustomerContactId').value);
			const company = document.getElementById('iwc_CustomerCompany').value;
			const name = document.getElementById('iwc-inputName').value;
            		const location = document.getElementById('iwc_CustomerLocation').value;
			chatState.customerName = name ? name : document.getElementById('iwc_CustomerName').value;
			const email = document.getElementById('iwc_CustomerEmail').value;
			
			return {
					'organizationId': orgId,
					'deploymentId' : deploymentId,
					'routingTarget' : {
						'targetType' : 'queue',
						'targetAddress': chatQueue
					},
					'memberInfo' : { 
						'displayName' : chatState.customerName,
						'email' : email,
						'customFields' : {
							'location':(location ?? ''),
							'contact_id': (contactId ?? ''),
							'company' : (company ?? ''),
							'email' : (contactId ? email : '')
						}
					}
				}
		}	

		const setupChat = () => {
			const section = document.getElementById('iwc-chatSection');
			section.classList.remove('hide');
			dragElement.Bind(section.getElementsByClassName('iwc-main-panel-header')[0]);

			const sendButton = document.querySelector('#iwc-sendButton');

			sendButton.removeEventListener('click', onSendClicked);
			sendButton.addEventListener('click', onSendClicked);

			document.getElementById('iwc-exitChatButton').removeEventListener('click', onExitClicked);
			document.getElementById('iwc-exitChatButton').addEventListener('click', onExitClicked);

			// check if in chat pop-out window
			if (contactChatBtn) {
				document.getElementById('iwc-popout-chat').addEventListener('click', function(e) {
					e.preventDefault();
					displayChatPopout(e);
				});
			} else {
				document.getElementById('iwc-popout-chat').parentNode.removeChild(document.getElementById('iwc-popout-chat'));
			}

			document.getElementById('iwc-confirmExit').removeEventListener('click', onConfirmExitClicked);
			document.getElementById('iwc-confirmExit').addEventListener('click', onConfirmExitClicked);
			
			document.getElementById('iwc-confirmCancel').removeEventListener('click', onConfirmCancelClicked);
			document.getElementById('iwc-confirmCancel').addEventListener('click', onConfirmCancelClicked);

			document.getElementById('iwc-inputPanel').removeEventListener('keydown', onEnterPressed);
			document.getElementById('iwc-inputPanel').addEventListener('keydown', onEnterPressed);
		}

		const onSendClicked = function(e) {
			e.preventDefault();
			forms4i().formValidation(document.querySelector('#iwc-compose-message-form'), onMessageValidation, e, onMessageFailure);
		}
		
		const onMessageValidation = () => {
			var element = document.querySelector('#iwc-inputPanel');

			if (element && element.value) {
				sendMessage(element);
			} 
		}
		
		const onMessageFailure = () => {
			let sendButton = document.querySelector('#iwc-sendButton');
			if(sendButton){
				sendButton.removeEventListener('click', onSendClicked);
				sendButton.addEventListener('click', onSendClicked);
			}
			document.querySelector('#iwc-inputPanel').focus();
		}
		
		const onExitClicked = function(e) {
			e.preventDefault();
			displayConfirm(true);
		}

		const onConfirmExitClicked = function(e) {
			e.preventDefault();
			document.getElementById('iwc-agentWaiting-exit').classList.remove('hide');
			e.target.disabled = true;
			document.getElementById('iwc-confirmCancel').disabled = true;
			exitChat(true);
		}
		
		const onConfirmCancelClicked = function(e) {
			e.preventDefault();
			displayConfirm(false);
		}


		const onEnterPressed = function(e) {
			if (!e.shiftKey && e.keyCode === 13) {
				e.preventDefault();
				const sendButton = document.getElementById('iwc-sendButton');
				sendButton.click();
			} else {

				// If picked up, check to see if should send typing indicator
				if (pickedUp) {
					sendTypingIndicator();
				}	
			}
		}

		const initChat = function() {
			setupChat();
			chatService.SendStart(getChatRequest(),
				function(response) {
					if (response) {
						chatState.conversationId = response.id;
						chatState.memberId = response.member.id;
						chatState.chatInfo = response;
						chatState.status = chatStatuses.open;
						chatState.inPopout = !contactChatBtn;
						chatStorage.Save();
						enableChatInputs();
					} else {
						logError('Chat: failed initializing chat', { 'Error': JSON.stringify(response) });
						displayChatError();
					}
				},
				processWebSocketMessage,
				function(error) {
					logError('Chat: failed initializing chat', { 'Error': JSON.stringify(error) });
					displayChatError();
				}
			)
		}

		// END Setup


		const updateAgentStatus = function(connected) {
			if (connected) {
				document.getElementById('iwc-agentName').innerText = chatState.agentName;
				const busy = document.getElementById('iwc-agentWaiting');
				if(busy){
					busy.classList.add('hide');
					busy.setAttribute('aria-busy', 'false');
				}
				const status = document.getElementById('iwc-agentStatus');
				if(status){
					status.setAttribute('aria-hidden', 'false');
					status.classList.remove('hide');
				}
				// ML added 
				//remove wait time text
				if(document.getElementById("chatWaitTimeInfo")){
					clearInterval(waitTimer); 
					document.getElementById("chatWaitTimeInfo").parentNode.removeChild(document.getElementById("chatWaitTimeInfo"));
				}
				// add rep has entered text
				var repHasNotEntered = document.querySelector(".csrEntered") ? false : true;
				if(repHasNotEntered){
					var enteredChat = "<li class='csrEntered marginTop10 marginBtm10'>" + chatState.agentName + " has entered the chat.</li>";
					document.getElementById("iwc-chatList").insertAdjacentHTML("beforeend", enteredChat);
				}
			} else {
				if(document.getElementById('iwc-agentName') && document.getElementById('iwc-agentName').innerText){
					document.getElementById('iwc-agentName').innerText = chatState.agentName + ' left chat';
				}
				const busy = document.getElementById('iwc-agentWaiting');
				if(busy){
					busy.classList.remove('hide');
					busy.setAttribute('aria-busy', 'true');
				}
				const status = document.getElementById('iwc-agentStatus');
				if(status){
					status.setAttribute('aria-hidden', 'true');
					status.classList.add('hide');
				}
			}
		}

		const reloadState = function() {
			pickedUp = !!chatState.agentName;
			document.getElementById('iwc-startSection').classList.add('hide');
			document.getElementById('iwc-chatSection').classList.remove('hide');
			setupChat();

			chatService.SendReconnect(chatState.chatInfo, 
				processWebSocketMessage,
				function(response) {
					enableChatInputs();
				},
				function(error) {
					displayChatError();
					logError('Chat: failed reconnecting chat', { 'Error': JSON.stringify(error) });
				}
			);

			if (pickedUp) {
				updateAgentStatus(true);
			}

			if (chatState.top || chatState.left) {
				const element = mainPanel ? mainPanel : document.getElementById('iwc-mainChatPanel');
				element.style.top = chatState.top;
				element.style.left = chatState.left;
				element.style.bottom = 'auto';
			}

			if (chatState.inPopout && document.getElementById('iwc-mainChatPanel')) {
				document.getElementById('iwc-mainChatPanel').style.height = 'auto';
			}

			for (var i = 0; i < chatState.chatHistory.length; i++) {
				chatState.chatHistory[i].time = new Date(chatState.chatHistory[i].time);
				addChatItem({
					displayName: chatState.chatHistory[i].name,
					value: chatState.chatHistory[i].message,
					participantType: chatState.chatHistory[i].type
				}, true)
			}
		}

		const displayChatStartError = function(btn) {
			const label = document.getElementById('iwc-start-description');
			label.classList.add('ErrorMsgPanel');
			label.innerHTML = document.getElementById('iwc_ChatWaitTimeError').value;
			btn.disabled = true;
			btn.classList.add('disabledBtn');
		}

		const displayChatPopout = function(e) {
			e.target.disabled = true;

			if (chatPopoutWindow().DisplayWindow()) {
				closeChat();
				chatState.status = chatStatuses.close;
				chatStorage.Save();
			} else {
				e.target.disabled = false;
			}
		}

		const displayConfirm = function(display) {
			const sendBtn = document.getElementById('iwc-sendButton');
			const confirm = document.getElementById('iwc-confirm-exit');
			const popoutBtn = document.getElementById('iwc-popout-chat');

			document.getElementById('iwc-inputPanel').disabled = display;
			sendBtn.disabled = display;
			popoutBtn && (popoutBtn.disabled = display);

			if (display) {
				confirm.classList.remove('hide');
				document.getElementById('iwc-confirmExit').focus();
				document.getElementById('iwc-exitChatButton').classList.add('hide');
				sendBtn.classList.add('disabledBtn');
				popoutBtn && popoutBtn.classList.add('visually-hidden');
			} else {
				confirm.classList.add('hide');
				document.getElementById('iwc-inputPanel').focus();
				document.getElementById('iwc-exitChatButton').classList.remove('hide');
				popoutBtn && popoutBtn.classList.remove('hide');
				sendBtn.classList.remove('disabledBtn');
				popoutBtn && popoutBtn.classList.remove('visually-hidden');
			}
		}

		// Web Socket events
		const processWebSocketMessage = function(e) {

			var msg = JSON.parse(e.data);

			if (msg.topicName && msg.topicName == "channel.metadata"
				&& msg.eventBody.message == "WebSocket Heartbeat") {
					lastHeartbeat = Date.now();
					clearInterval(heartbeatTimer);
					startHeartbeatTimer();

				return;
			}
			// Chat message
			if (msg.metadata) {				
				switch(msg.metadata.type) {
					case 'message': {
						onMessage(msg);
					break;
					}
					case 'typing-indicator':{
						onTyping(msg);
					break;
					}
					case 'member-change': {
						break;
					}
					default: {
						console.log('Unknown message type: ' + msg.metadata.type);
						break;
					}
				}
				
			}
		}

		const startHeartbeatTimer = function(){
			heartbeatTimer = setInterval(function() {
				// If we havent received a heartbeat in 10 seconds, assume disconnected socket
				if(lastHeartbeat < (Date.now() - 10000)){
					console.error("Socket disconnected, reconnecting");
					clearInterval(heartbeatTimer);
					websocketReconnect();
				}
				
			}, 10500); // check every 10.5 seconds
		}
		// Handles any text message events from WebSocket connection
		const onMessage = function(message) {

			// Check for duplicates
			if (isDuplicateMessage(message.eventBody.id, chatState.chatHistory)) {
				logError('Chat: duplicate chat message found', { 'Event': message.eventBody.id + '-' + message.eventBody.sender.id });
				return;
			}

			var event = {
				memberId: message.eventBody.sender.id,
				sequence: message.eventBody.id,
				value: message.eventBody.body
			};

			switch (message.eventBody.bodyType) {
				case 'standard':
				case 'notice':
					if(isAgent(event.memberId)){
						if(agentTyping) {
							agentTyping = false;
							clearTimeout(agentTypingTimeout);
							hideAgentTypingIndicator();
						}
		
						event.participantType = 'Agent';
						event.displayName = chatState.agentName;
						addChatItem(event);
						
					} else if(isCustomer(event.memberId)) {
						event.participantType = 'WebUser';
						event.displayName = chatState.customerName;
						addChatItem(event);
						
					} else{
						event.participantType = 'System';
						event.displayName = 'System';
						addChatItem(event);
					}
					break;
				case 'member-join':
					if(!isCustomer(event.memberId)){
						onAgentConnected(event.memberId);
					}
					break;
				case 'member-leave':
					if(isAgent(event.memberId)){
						updateAgentStatus(false);
					} else if(isCustomer(event.memberId)){
						exitChat();
					}
					break;
				default: 
					// ignoring member-join, member-leave as they are redundant - handled with member-change
					event.participantType = 'System';
					addChatItem(event);
			}
		}
		
		// Gets agent name. Sets agent name and id in chat state. Updates agent status. Sets pickedUp
		const onAgentConnected = function(agentMemberId){
			chatService.GetMemberInfo(
				chatState.conversationId,
				agentMemberId,
				function(response) {
					if (response) {
						if(response.role == 'AGENT') {
							chatState.agentName = response.displayName ? response.displayName : '4i Agent';
							chatState.agentMemberId = agentMemberId;
							chatStorage.Save();
							updateAgentStatus(true);
							pickedUp = !pickedUp ? true : pickedUp;
						} else if(response.role == 'ACD'){
							console.log('ACD Queue: ' + response.displayName);
						} else{
							console.log('Non-agent member:' + (JSON.stringify(response)));
						}
						
					} else {
						logError('Chat: failed retrieving member info', { 'Error':  JSON.stringify(response) });
						displayChatError();
					}
				},
				function(error) {
					logError('Chat: failed initializing chat', { 'Error': JSON.stringify(error) });
					displayChatError();
				}
			)
		}


		const addChatItem = function(event, reloading) {
			const listBox = document.getElementById('iwc-chatList');
			const listItem = document.createElement('li');

			switch (event.participantType) {
				case 'System':
					createItem(event, false, false, reloading);
					return;
				case 'Ended':
					listItem.classList.add('iwc-message-from-system');
					listItem.classList.add('displayFlex');
					listItem.classList.add('smallSpace')
					listItem.innerHTML = '<span class="iwc-chat-participant-avatar"></span>'
						+ '<div class="paddingTop5 paddingLeft5">'
						+ chatState.agentName + ' has left the chat...'
						+ '</div>';
						event.participantType = lastMessageFrom;
					break;
				case 'AgentTyping':
					listItem.classList.add('iwc-message-from-system');
					listItem.id = 'agentTyping';
					listItem.classList.add('displayFlex');
					listItem.classList.add('smallSpace')
					listItem.innerHTML = '<span class="iwc-chat-participant-avatar"></span>'
						+ '<div class="paddingTop5 paddingLeft5">'
						+ chatState.agentName + ' is typing...'
						+ '</div>';
						event.participantType = lastMessageFrom;
					break;
				case 'Agent':
					listItem.classList.add('iwc-message-from-agent');
					listItem.classList.add('displayFlex');
					lastMessageFrom !== event.participantType ? listItem.classList.add('smallSpace') : listItem.classList.add('paddingTop2');
					listItem.innerHTML = createItem(event, true, lastMessageFrom !== event.participantType, reloading)
					break;
				case 'Error':
					listItem.classList.add('ErrorMsgPanel');
					listItem.innerHTML = 'Could not connect to chat server.';
					break;
				case 'WebsocketError':
					listItem.classList.add('ErrorMsgPanel');
					listItem.classList.add('websocketError');
					listItem.innerHTML = 'Connection interrupted, reconnecting...';
					break;
				default: // From user
					listItem.classList.add('iwc-message-from-self');
					listItem.classList.add('displayFlex');
					listItem.classList.add('flexJustifyEnd');
					lastMessageFrom !== event.participantType ? listItem.classList.add('smallSpace') : listItem.classList.add('paddingTop2');
					listItem.innerHTML = createItem(event, false, false, reloading);
			}

			lastMessageFrom = event.participantType;
			listBox && listBox.appendChild(listItem);
			listItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}

		// Creates html formatted message and optionally adds to chat history
		const createItem = function(event, showIcon, firstAgentMessage, reloading = false) {
			const text = chatService.CheckForFile(event.value);

			// only adds when not reloading
			addToChatHistory(
				{
					time: new Date(),
					name: event.displayName,
					message: text,
					memberId: event.memberId,
					type: event.participantType,
					sequence: event.sequence
				}, 
				reloading);
			
				
			return '<div class="visually-hidden">' + event.displayName + '</div>'
				+ (showIcon ? (firstAgentMessage ? '<span class="iwc-chat-participant-avatar"></span>' : '<span class="iwc-blank-participant-avatar"></span>') : '')
				+ '<div class="iwc-message-text">'
				+ checkForLink(text)
				+ '</div>'
		}

		const addToChatHistory = function(event, reloading) {
			if (!reloading) {
				chatState.chatHistory.push(event);
				chatStorage.Save();
			}

		}
		// Processes Agent typing events received by WebSocket
		const onTyping = function(event) {

			// if sender is not customer
			if(!isCustomer(event.eventBody.sender.id)){

				// If agent still typing
				if(agentTyping){
					  // reset timer
					  clearTimeout(agentTypingTimeout);
					  startAgentTypeTimeout();
					
				}else{
					// agent started typing
					agentTyping = true;
					showAgentTypingIndicator();
					startAgentTypeTimeout();
				}
			}
		}

		const startAgentTypeTimeout = function() {
			agentTypingTimeout = setTimeout(() => {
				agentTyping = false;
				hideAgentTypingIndicator();
			}, 4000);
		}
		const showAgentTypingIndicator = function() {
			var typingElem = document.getElementById('agentTyping');

			if(!typingElem){
				addChatItem({
					participantType: 'AgentTyping'
				});
			}
		}

		const hideAgentTypingIndicator = function() {
			var typingElem = document.getElementById('agentTyping');
			if(typingElem){
				typingElem.parentNode.removeChild(typingElem);
			}
		}

		// END Web Socket events
		

		// START EWT
		
		const startTimeCountDown = function(waitTime){
			waitTimer = setInterval(function() {
				updateWaitText(waitTime); // update the wait time
				if(waitTime == 60) clearInterval(waitTimer); // when it hits 60 seconds, stop looking
			}, 60000); // check every 60 seconds
		}
		
		const updateWaitText = function(time){
			if(document.getElementById("chatWaitTimeInfo")){
				document.getElementById("chatWaitTimeInfo").parentNode.removeChild(document.getElementById("chatWaitTimeInfo"));
			}
			if(document.getElementById("iwc-chatList")){
				// add wait time text
				var waitTime = time <= 60 ? "1 minute" : Math.ceil(time / 60.0).toString() + " minutes";
				var chatWaitTime = '<li id="chatWaitTimeInfo" class="paddingBtm10" role="status">One of our Customer Service Reps will reply in approximately ' + waitTime + '.</li>';
				document.getElementById("iwc-chatList").insertAdjacentHTML("afterbegin", chatWaitTime);
			}
		}

		// END EWT
	

		
		const websocketReconnect = function() {
			displayWebsocketError();
			chatService.SendReconnect(chatState.chatInfo,
				processWebSocketMessage,
				websocketReconnected,
				function(error) {
					displayChatError();
					logError('Chat: failed reconnecting chat', { 'Error': JSON.stringify(error) });
				}
			);
		}

		const displayWebsocketError = function() {
			chatState.status = chatStatuses.close;
			chatStorage.Save();
			addChatItem({ 
				participantType: 'WebsocketError'
			});
		}
		const websocketReconnected = function() {
			chatState.status = chatStatuses.open;
			document.querySelector('.websocketError').remove();

			chatService.GetMessageHistory(
				chatState.conversationId, rebuildMessages, 
				function(error) {
					console.log(error);
				})
			
			console.log('Websocket reconnected');
		}

		const rebuildMessages = function(resp) {


			if(resp && resp.entities) {
				resp.entities.forEach(function(msg) {
					var event = {
						memberId: msg.sender.id,
						sequence: msg.id,
						value: msg.body
					};
		
				if(!isDuplicateMessage(event.sequence, chatState.chatHistory, false)){
					switch (msg.bodyType) {
						case 'standard':
						case 'notice':
							if(isAgent(event.memberId)){
								event.participantType = 'Agent';
								event.displayName = chatState.agentName;
								addChatItem(event);
								
							} else if(isCustomer(event.memberId)) {
								event.participantType = 'WebUser';
								event.displayName = chatState.customerName;
								addChatItem(event);
								
							} else{
								event.participantType = 'System';
								event.displayName = 'System';
								addChatItem(event);
							}
							break;
						default:
							break;
						}
				}
					
				});
			}
		}

		const chatErrorRetry = function(retry) {
			chatService.SendReconnect(chatState.chatInfo,
				processWebSocketMessage,
				retry(),
				function(error) {
					displayChatError();
					logError('Chat: failed reconnecting chat', { 'Error': JSON.stringify(error) });
				}
			);
		}

		const displayChatError = function(errorMsg  = null) {
			chatState.status = chatStatuses.close;
			chatStorage.Save();
			addChatItem({ 
				participantType: 'Error',
				errorMessage: errorMsg }
				);
		}
		
		const enableChatInputs = function() {
			// disable inputs for slow devices - have to wait until successful chat start api call
			const panel = document.getElementById('iwc-inputPanel');
			if(panel){
				panel.disabled = false;
				panel.focus();
			}
			const btn = document.getElementById('iwc-sendButton');
			btn.disabled = false;
			btn.classList.remove('disabledBtn');
		}

		const disableChatInputs = function() {
			// disable inputs for slow devices - have to wait until successful chat start api call
			const panel = document.getElementById('iwc-inputPanel');
			if(panel) panel.disabled = true;
			const btn = document.getElementById('iwc-sendButton');
			btn.disabled = true;
			btn.classList.add('disabledBtn');
		}
			
		

		const sendMessage = function(element) {
			
			const sendMessageRequest = {
				'body': element.value,
				'bodyType': 'standard'
			};
			disableChatInputs();
			chatService.SendMessage(
				sendMessageRequest,
				chatState.conversationId,
				chatState.memberId,
				function(response) {
					// message added to chat when websocket message received
					enableChatInputs();
						element.value = '';
						element.focus();
				},
				function(error) {
					logError('Chat: failed sending chat message', { 'Error': JSON.stringify(error) });
					chatErrorRetry(function() {
						sendMessage(element);
					});
				}
			);
		}

		// Typing Indicator

		const sendTypingIndicator = function() {

			const now = new Date();
		
			// If last sent time hasnt been set (never sent) OR is greater than 3 seconds ago, send the indicator
			if (!lastTypeTime || (now - lastTypeTime) > 3000) {
				chatService.SendTyping(chatState.conversationId, chatState.memberId,
					function(response) {
						lastTypeTime = new Date()
					},
					function(error) {
						logError('Chat: failed sending chat typing indicator', { 'Error': JSON.stringify(error) });
						chatErrorRetry(function() {
							sendTypingIndicator();
						})
					}
				);
				
			}
		}
		
		// END Typing Indicator



		// Chat Close

		const exitChat = function(unload) {

			clearInterval(heartbeatTimer);
			if (chatState.status === chatStatuses.close) {
				initSurvey();
				return;
			}

			if(unload){
				
				// if agent disconnected, no need to send the delete
				chatService.SendExit(chatState.memberId, unload, chatState.conversationId,
					function(response) {
						chatState.status = chatStatuses.close;
						chatStorage.Save();
						pickedUp ? chatEnded({}) : closeChat();
					},
					function(error) {
						chatState.status = chatStatuses.close;
						chatStorage.Save();
						pickedUp ? chatEnded({}) : closeChat();;
						logError('Chat: failed sending exit chat', { 'Error': JSON.stringify(error) });
					}
				);
			}

			chatState.status = chatStatuses.close;
			chatStorage.Save();
			pickedUp ? chatEnded({}) : closeChat();
		}

		const chatEnded = function(event) {
			event.participantType = 'Ended';
			addChatItem(event);
			const status = document.getElementById('iwc-agentStatus');
			status && (status.innerHTML = 'Disconnected')
			chatState.status = chatStatuses.close;
			chatStorage.Save();
			initSurvey();
		}

		const initThankYou = function() {
			document.getElementById('iwc-surveySection').classList.add('hide');
			const section = document.getElementById('iwc-endSection');
			section.classList.remove('hide');

			dragElement.Bind(section.getElementsByClassName('iwc-main-panel-header')[0]);

			const button = document.getElementById('iwc-printChatEnd');
			button.focus();
			button.addEventListener('click', function(e) {
				e.preventDefault();
				new printWindow().DisplayPrintWindow(chatState.chatHistory);
			});

			document.getElementById('iwc-exitEndButton').addEventListener('click', function(e) {
				e.preventDefault();
				closeChat();
			});
		}

		const initSurvey = function() {
			const list = document.getElementById('iwc-chatList');
			/*if (!list || list.getElementsByTagName('li').length <= 2) {
				closeChat();
				return;
			}*/
			
			const chatSection = document.getElementById('iwc-chatSection');
			if(chatSection) chatSection.classList.add('hide');
			
			const section = document.getElementById('iwc-surveySection');
			if(section){
				section.classList.remove('hide');
				const header = section.getElementsByClassName('iwc-main-panel-header')[0];
				dragElement.Bind(header);
			}
			
			const button = document.getElementById('iwc-printChat');
			if(button){
				button.focus();
				button.addEventListener('click', function(e) {
					e.preventDefault();
					new printWindow().DisplayPrintWindow(chatState.chatHistory);
				});
			}
			const exitBtn = document.getElementById('iwc-exitSurveyButton');
			if(exitBtn){
				exitBtn.addEventListener('click', function(e) {
					e.preventDefault();
					closeChat();
				});
			}

			// survey submitting twice on click in some instances, remove and readd. 
			const ratingBtn = document.getElementById('iwc-submitRating');
			if(ratingBtn){
				ratingBtn.removeEventListener('click', submitSurvey);
				ratingBtn.addEventListener('click', submitSurvey);
			}
			
			var reviewRating = document.getElementById("iwc-reviewRatingScale");
			if(reviewRating){
				Array.prototype.forEach.call(reviewRating.getElementsByClassName("iwc-rating"), function(element) {
					element.addEventListener("click", function(e) {
						e.preventDefault();
						starEvents.RemoveSelectedStar(reviewRating);
						starEvents.SelectRating(element);
					});
					element.addEventListener("mouseover", function(e) {
						starEvents.ColorAllPrevSiblings(element);
					});
					element.addEventListener("mouseout", function(e) {
						starEvents.RemoveColorOnPrevSiblings(element, reviewRating);
					});
				});
			}
		}

		const submitSurvey = function(e) {
			e.preventDefault();
			forms4i().formValidation(document.getElementById("iwc-surveySection-form"),
				function() {
					e.target.disabled = true;
					ajaxActions.SaveSurvey(document.getElementById('iwc-surveySection'));
				},
				e
			);
		};

		const closeChat = function() {
			const element = mainPanel ? mainPanel.parentElement : document.getElementById('iwc-mainChatPanel').parentElement;

			if (element) {
				//element.classList.add('hide');
				element.classList.remove("open");

				while (element.firstChild) {
					element.removeChild(element.firstChild);
				}
			}
			
			// ML added
			clearInterval(waitTimer);
			clearInterval(heartbeatTimer);
			
			const script = document.getElementById('chatScript');
			script && script.parentNode.removeChild(script);

			if (contactChatBtn) {
				contactChatBtn.classList.remove('bkgdBlue');
				contactChatBtn.classList.remove('whiteLink');
				contactChatBtn.classList.add('btnToHyp');
				contactChatBtn.classList.add('pointer');
				contactChatBtn.setAttribute('aria-expanded', 'false');
				contactChatBtn.disabled = false;
				if(document.querySelector(".chat-active")){
					document.querySelector(".chat-active").focus();
					document.querySelector(".chat-active").classList.remove("chat-active");
				}else{
					contactChatBtn.focus();
				}
			} else {
				window.close();
			}
		};

		
		// END Chat Close

		const printWindow = function() {
			const displayPrintWindow = function(messages) {
				popUpWindow(formatMessagesIntoHtml(messages));
			}

			const formatMessagesIntoHtml = function(messages) {
				const pre = '<html>' +
					'<head><title>Chat History</title></head>' +
					'<body>' +
					'<a href="javascript:window.print()">Print</a>' +
					'<table style="padding-left:10px">';

				const post = '</table>' +
					'<a href="javascript:window.print()">Print</a>' +
					'<p><i>*Some links may not be valid after the chat ends.</i></p>' +
					'<script type="text/javascript">(function() {document.getElementsByTagName("a")[0].focus();})();</script>'
				'</body>' +
					'</html>';

				var content = '';
				var displayFullDate = true;
				for (var i = 0; i < messages.length; ++i) {
					content += formatMessageIntoHtml(messages[i], displayFullDate);
					displayFullDate = false;
				}

				return pre + content + post;
			}

			const formatMessageIntoHtml = function(message, fullDate) {
				return '<tr><td style="text-align:right; padding-right: 10px;">'
					+ (fullDate ? message.time.toLocaleDateString() + ' ' : '')
					+ message.time.toLocaleTimeString()
					+ '</td><td style="text-align:right; padding-right: 10px;">' + message.name + ':</td><td class="message">'
					+ checkForLink(escapeHTML(message.message).replace(/http/gi, " http"), createGenericLink) + '</td></tr>';
			}

			const escapeHTML = function(str) {
				str = str.replace(/&/g, '&amp;');
				str = str.replace(/</g, '&lt;');
				str = str.replace(/>/g, '&gt;');
				return str;
			};

			const popUpWindow = function(html) {
				const windowTitle = 'Chat_History';
				const windowFeatures = 'width=' + (screen.width * 3 / 4) + ', height=' + (screen.height * 3 / 4) + ', toolbar=no, location=no, status=no, menubar=yes, scrollbars=yes, resizable=yes';

				try {
					const newWin = window.open('', windowTitle, windowFeatures);
					newWin.document.write(html);
					newWin.document.close();
				}
				catch (ex) {
					window.alert('Error Opening Window. Verify pop-ups are not blocked.');
				}
			}

			const createGenericLink = function(fullUrl, schema, afterSchema) {
				if (!schema) {
					// Found a URL that was specified without a scheme, e.g. "www.inin.com"
					// Fix up the values so that they are what they'd be if scheme had been provided.
					schema = "http://";
					afterSchema = fullUrl;
					fullUrl = schema + afterSchema;
				}
				var text = "http://" === schema.toLowerCase() || "https://" === schema.toLowerCase() || "mailto:" === schema.toLowerCase() ? chatService.GetFileName(afterSchema) : '';

				return '</br><a href="' + fullUrl + '" target = "_blank" > ' + (text || fullUrl) + '</a > ';
			}

			return {
				DisplayPrintWindow: displayPrintWindow
			};
		}

		const chatPopoutWindow = function() {
			const displayWindow = function() {
				return popUpWindow();
			}

			const popUpWindow = function() {
				const windowTitle = '4imprint_Chat';
				const windowFeatures = 'width=350, height=520, toolbar=no, location=no, status=no, menubar=yes, scrollbars=yes, resizable=yes';

				try {
					const newWin = window.open('', windowTitle, windowFeatures);
					newWin.document.head.appendChild(getScript(newWin.document));
					newWin.document.close();
					return true;
				}
				catch (ex) {
					window.alert('Error Opening Window. Verify pop-ups are not blocked.');
					return false;
				}
			}

			const getScript = function(newDoc) {
				chatState.inPopout = true;
				chatStorage.Save();

				const script = newDoc.createElement('script');
				script.type = 'text/javascript';
				script.text = 'var t = "' + btoa(JSON.stringify(chatState)) +'";sessionStorage.setItem("chatState", atob(t));'
					+ 'location.href = "' + location.origin + '/chat/chatpopout";';

				logError("Chat: Popout script - " + script.text);

				return script;
			}

			return {
				DisplayWindow: displayWindow
			};
		}

		// START Utility
		const isAgent = function(memberId) {

			return (chatState.agentMemberId && chatState.agentMemberId == memberId);
		}

		const isCustomer = function(memberId) {

			return (chatState.memberId && chatState.memberId == memberId);
		}

		const isDuplicateMessage = function(sequenceId, chatHistory, reloading = false) {
			if (!reloading && sequenceId) {
				for (var i = 0; i < chatHistory.length; i++) {
					if (chatHistory[i].sequence === sequenceId) {
						return true;
					}
				}
			}

			return false;
		}

		const checkForLink = function(text, parser) {
			// The following regular expression will match URLs specified with a
			// scheme (e.g. http://www.inin.com or http://www.inin.com/directory/file?key=value
			// or ftp://www.inin.com or mailto:support@inin.com).
			// It will also match URLs with no scheme specified (e.g. www.inin.com), IF they
			// are of the form "www dot something"
			//                                          (               scheme              )(URL) |(    www...     )
			const urlMatchingRegularExpression = /(?:(?:((?:(?:https?|ftp):\/\/)|(?:mailto:))(\S+))|(www\.[^\.\s]\S+))/ig;

			return text.replace(/http/gi, " http").replace(urlMatchingRegularExpression, typeof parser === 'function' ? parser : createLink);
		}
				

		const createLink = function(fullUrl, schema, afterSchema) {
			if (!schema) {
				// Found a URL that was specified without a scheme, e.g. "www.inin.com"
				// Fix up the values so that they are what they'd be if scheme had been provided.
				schema = "http://";
				afterSchema = fullUrl;
				fullUrl = schema + afterSchema;
			}
			var text = "http://" === schema.toLowerCase() || "https://" === schema.toLowerCase() || "mailto:" === schema.toLowerCase() ? chatService.GetFileName(afterSchema) : '';

			var id = checkForProductUrl(fullUrl);

			return '<a href="' + fullUrl + '"' + (id === fullUrl ? '' : ' id="' + id + '" class="hide"')
				+ ((fullUrl.indexOf('www.4imprint.') === -1 || !contactChatBtn) ? ' target = "_blank"' : '') + ' > ' + (text || fullUrl) + '</a > ';
		}

		const checkForProductUrl = function(url) {
			var regex = /(.+)(\/product\/)(.+)(\/+)(.*)/i;

			return url.replace(regex, ajaxActions.GetProductInfo);
		}

		const logError = function(message, properties) {
			typeof appInsights !== 'undefined' && appInsights.trackTrace({ 'message': message, 'properties': properties, 'severityLevel': appInsights.SeverityLevel.Error });
		}

		// END Utility

		// START Chat Service
		const chatService = (function() {		
			var apiUrl = document.getElementById('iwc_ChatUrl').value;
			let chatInfo, socket;

			const fileEndpoint = 'chat/getfile/'; // TODO follow up

			const header = {
				'Accept': 'application/json',
				'Accept-Charset': '*',
				'Content-Type': 'application/json'
			}

			// Send request to start chat
			const sendStart = function(request, success, onWebsocketMessage, errorHandler) {
				fetch(apiUrl + '/api/v2/webchat/guest/conversations', {
					method: 'POST',
					cache: 'no-cache',
					headers: header,
					body: JSON.stringify(request)
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then((createChatResponse) => {
					// Store chat info
					chatInfo = createChatResponse;
				
					connectWebSocket(onWebsocketMessage);
					// Set JWT
					header.Authorization = 'Bearer ' + chatInfo.jwt;

					success(createChatResponse)
				}).catch(function(error) {
					errorHandler(error);
				});
			}

			// connects to web socket service and adds event listeners
			const connectWebSocket = function(onWebsocketMessage) {
				// Connect to notifications
				socket = new WebSocket(chatInfo.eventStreamUri);
				
				// Connection opened
				socket.onopen = (event) => {
					console.log('WebSocket connected');
				}

				// Listen for messages
				socket.removeEventListener("message", onWebsocketMessage);
				socket.addEventListener("message", onWebsocketMessage);
								
			}
			// Get agent name on connect
			const getMemberInfo = function(conversationId, memberId, success, errorHandler) {

				fetch(apiUrl + `/api/v2/webchat/guest/conversations/${conversationId}/members/${memberId}`, {
					method: 'GET',
					cache: 'no-cache',
					headers: header
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then((getMemberInfoResponse) => {
					success(getMemberInfoResponse)
				}).catch(function(error) {
					errorHandler(error);
				});
			}

			const getMessageHistory = function(conversationId, success, errorHandler) {

				fetch(apiUrl + `/api/v2/webchat/guest/conversations/${conversationId}/messages`, {
					method: 'GET',
					cache: 'no-cache',
					headers: header
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then((getMessagesResponse) => {
					success(getMessagesResponse)
				}).catch(function(error) {
					errorHandler(error);
				});
			}
			
			// Sends a chat message
			const sendMessage = function(request, conversationId, memberId, success, errorHandler){
				// /api/v2/webchat/guest/conversations/{conversationId}/members/{memberId}/messages
				// https://developer.genesys.cloud/commdigital/digital/webchat/guestchat#post-api-v2-webchat-guest-conversations--conversationId--members--memberId--messages
				fetch(apiUrl + `/api/v2/webchat/guest/conversations/${conversationId}/members/${memberId}/messages`, {
					method: 'POST',
					cache: 'no-cache',
					headers: header,
					body: JSON.stringify(request)
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then((getMemberInfoResponse) => {
					success(getMemberInfoResponse)
				}).catch(function(error) {
					errorHandler(error);
				});
			}

			// Sends typing indicator
			const sendTyping = function(conversationId, memberId, success, errorHandler) {
				// /api/v2/webchat/guest/conversations/{conversationId}/members/{memberId}/typing
				fetch(apiUrl + `/api/v2/webchat/guest/conversations/${conversationId}/members/${memberId}/typing`, {
					method: 'POST',
					cache: 'no-cache',
					headers: header
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then(function(response) {
					success(response);
				}).catch(function(error) {
					errorHandler(error);
				});
			}

			// Get guest connect state and reconnect or disconnect as appropriate
			const sendReconnect = function(chatInfoCopy, onWebSocketMessage, success, errorHandler) {

				// Reinit chat info
				chatInfo = chatInfoCopy;
				// Set JWT
				header.Authorization = 'Bearer ' + chatInfo.jwt;

				getMemberInfo(
					chatInfo.id, 
					chatInfo.member.id,
					function(response) {
						if(response.state === 'CONNECTED'){
							// reconnect
							connectWebSocket(onWebSocketMessage);
							success();
						} else{
							socket.close();
							errorHandler();
						}
					},
					errorHandler
					);
			}

			// TODO: probably need to point this to blob storage
			const checkForFile = function(text) {
				if(text) {
					const pos = text.indexOf(fileEndpoint);
					var file = text;

					if (pos >= 0) {
						file = apiUrl + file.substring(pos)
					}

					return file;
				}
				return text;
			}

			const getFileName = function(url) {
				if (url.indexOf(fileEndpoint) >= 0) {
					const tokens = url.split("/");
					if (tokens && tokens.length) {
						return tokens[tokens.length - 1];
					}
				}

				return url;
			}

			const sendExit = function(memberId, unload, conversationId, success, errorHandler) {

				// Need to figure out what this is doing
				// if (unload) {
				// 	// navigator && navigator.sendBeacon && navigator.sendBeacon(apiUrl + exitEndpoint + memberId);
				// 	// success({});
				// }
				
				fetch(apiUrl + `/api/v2/webchat/guest/conversations/${conversationId}/members/${memberId}`, {
					method: 'DELETE',
					cache: 'no-cache',
					headers: header
				}).then(function(response) {
					return (response.ok) ? response.json() : {};
				}).then(function(response) {
					socket.close();
					success(response);
				}).catch(function(error) {
					socket.close();
					errorHandler(error);
				});
			}


			return {
				SendStart: sendStart,
				GetMemberInfo: getMemberInfo,
				GetMessageHistory: getMessageHistory,
				SendMessage: sendMessage,
				SendTyping: sendTyping,
				SendReconnect: sendReconnect,
				CheckForFile: checkForFile,
				GetFileName: getFileName,
				SendExit: sendExit
			}

		})();

		// END Chat Service

		// START Star events
		const starEvents = (function() {
			const getPrevSiblings = function(el) {
				const siblings = [];
				el = el.previousElementSibling;
				while (el) {
					siblings.push(el);
					el = el.previousElementSibling;
				}
				return siblings;
			}

			const getNextSiblings = function(el) {
				const siblings = [];
				el = el.nextElementSibling;
				while (el) {
					siblings.push(el);
					el = el.nextElementSibling;
				}
				return siblings;
			}

			const removeSelectedStar = function(container) {
				var span;
				Array.prototype.forEach.call(container.getElementsByTagName("button"), function(el) {
					el.classList.remove("selectedStar");
					el.setAttribute("aria-selected", "false");
					span = el.getElementsByTagName("span")[0];
					span.classList.remove("textDarkestGray");
					span.classList.add("textWhite");
				});
			}

			const colorAllPrevSiblings = function(e) {
				var span;
				getPrevSiblings(e).forEach(function(el) {
					span = el.getElementsByTagName("span")[0];
					span.classList.remove("textWhite");
					span.classList.add("textDarkestGray");
				});
			}

			const removeColorOnPrevSiblings = function(e, survey) {
				var selected = survey.getElementsByClassName("selectedStar").length > 0,
					span;

				if (!selected) {
					getPrevSiblings(e).forEach(function(el) {
						span = el.getElementsByTagName("span")[0];
						span.classList.add("textWhite");
						span.classList.remove("textDarkestGray");
					});
				}
				else {
					var selectedStar = survey.getElementsByClassName("selectedStar")[0];
					span = selectedStar.getElementsByTagName("span")[0];
					span.classList.remove("textWhite");
					span.classList.add("textDarkestGray");
					getNextSiblings(selectedStar).forEach(function(el) {
						span = el.getElementsByTagName("span")[0];
						span.classList.remove("textDarkestGray");
						span.classList.add("textWhite");
					});
				}
			}

			var starRating;

			const selectRating = function(e) {
				e.classList.add("selectedStar");
				e.setAttribute("aria-selected", "true");

				var span = e.getElementsByTagName("span")[0];
				span.classList.remove("textWhite");
				span.classList.add("textDarkestGray");

				getPrevSiblings(e).forEach(function(el) {
					span = el.getElementsByTagName("span")[0];
					span.classList.remove("textWhite");
					span.classList.add("textDarkestGray");
				});

				const btn = document.getElementById('iwc-submitRating');
				btn.disabled = false;
				btn.classList.remove('disabledBtn');
				btn.classList.add('bkgdBlue');
				btn.classList.add('whiteLink');

				starRating = e.getAttribute("data-starval");
			}

			return {
				RemoveSelectedStar: removeSelectedStar,
				ColorAllPrevSiblings: colorAllPrevSiblings,
				RemoveColorOnPrevSiblings: removeColorOnPrevSiblings,
				SelectRating: selectRating,
				get StarRating() { return starRating; }
			};
		})();

		// END Star events

		// START Drag element
		const dragElement = (function() {
			var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
			const element = mainPanel ? mainPanel : document.getElementById('iwc-mainChatPanel');

			const bind = function(header) {
				header.addEventListener("mousedown", dragMouseDown);
				header.addEventListener("touchstart", dragMouseDown);
			}

			const dragMouseDown = function(e) {
				e = e || window.event;
				e.preventDefault();

				// get the mouse cursor position at startup:
				if (e.type === "touchstart") {
					// check if button is touched and issue click event instead
					if (e.touches[0].target.tagName.toLowerCase() === 'button') {
						e.touches[0].target.click();
						return;
					} else if (e.touches[0].target.parentNode.parentNode.tagName.toLowerCase() === 'button') {
						e.touches[0].target.parentNode.parentNode.click();
						return;
					}

					pos3 = e.touches[0].clientX;
					pos4 = e.touches[0].clientY;
				} else {
					pos3 = e.clientX;
					pos4 = e.clientY;
				}

				document.addEventListener("touchend", closeDragElement);
				document.addEventListener("mouseup", closeDragElement);

				// call a function whenever the cursor moves:
				document.addEventListener("mousemove", elementDrag);
				document.addEventListener("touchmove", elementDrag);
			}

			const elementDrag = function(e) {
				e = e || window.event;
				e.type === "mousemove" && e.preventDefault();

				// calculate the new cursor position:
				pos1 = pos3 - (e.type === "touchmove" ? e.touches[0].clientX : e.clientX);
				pos2 = pos4 - (e.type === "touchmove" ? e.touches[0].clientY : e.clientY);
				pos3 = (e.type === "touchmove" ? e.touches[0].clientX : e.clientX);
				pos4 = (e.type === "touchmove" ? e.touches[0].clientY : e.clientY);

				// set the element's new position:
				element.style.top = (element.offsetTop - pos2) + "px";
				element.style.left = (element.offsetLeft - pos1) + "px";
				element.style.bottom = 'auto';
			}

			const closeDragElement = function(e) {
				e.type === "touchend" && e.preventDefault();

				// stop moving when mouse button is released:
				document.removeEventListener("touchend", closeDragElement);
				document.removeEventListener("mouseup", closeDragElement);
				document.removeEventListener("mousemove", elementDrag);
				document.removeEventListener("touchmove", elementDrag);

				chatState.top = element.style.top;
				chatState.left = element.style.left;
				chatStorage.Save();
			}

			return {
				Bind: bind
			}
		})();

		// END Drag element

		// START Ajax actions
		const ajaxActions = (function() {
			const saveSurvey = function(form) {
				const headerToken = form.querySelector('input[name=__RequestVerificationToken]').value;

				fetch('/chat/ChatSurvey', {
					method: 'POST',
					credentials: 'same-origin',
					body: JSON.stringify({
						ChatId: chatState.conversationId,
						ParticipantId: chatState.memberId,
						Rating: starEvents.StarRating,
						Comment: document.getElementById('iwc-surveyComment').value,
						Agent: chatState.agentName,
						CustomerName: chatState.customerName
					}),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', '__RequestVerificationToken': headerToken }
				}).then(function(response) {
					return (response.ok) ? response.json() : [];
				}).then(function(response) {
					if (!response.Message) {
						initThankYou();
					} else {
						const element = form.getElementsByClassName("formHeading")[0];
						element.innerHTML = response.Message.replace(/Comment/g, 'iwc-surveyComment');
						element.parentElement.classList.remove("hide");
						element.querySelector('a').focus();
					}
				}).catch(function(error) {
					logError('Chat: failed posting survey', { 'Error': JSON.stringify(error) });
				});
			}

			const getProductInfo = function(fullUrl, domain, product, catalogAlias, endSlash, following) {
				var id = Date.now().toString() + '_' + catalogAlias;

				fetch('/chat/product/' + catalogAlias + '/' + !contactChatBtn, {
					method: 'GET',
					credentials: 'same-origin',
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				}).then(function(response) {
					return (response.ok) ? response.text() : '';
				}).then(function(response) {
					const element = document.getElementById(id);
					if (response) {
						element.parentElement.insertAdjacentHTML('beforeend', response);
						element.parentElement.classList.remove('iwc-message-text')
						element.parentElement.classList.add('iwc-message-product')
						element.parentNode.removeChild(element);
					} else {
						element.classList.remove('hide');
					}
				}).catch(function(error) {
					if(document.getElementById(id)) document.getElementById(id).classList.remove('hide');
					logError('Chat: failed posting survey', { 'Error': JSON.stringify(error) });
				});

				return id;
			}

			const getWaitTime = function() {
				fetch('/chat/getchatwaittime', {
					method: 'GET',
					credentials: 'same-origin',
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
				}).then(function(response) {
					return (response.ok) ? response.text() : '';
				}).then(function(response) {
					const busy = document.getElementById('iwc-startWaiting');
					busy.setAttribute('aria-busy', false);
					busy.classList.add('hide');
					
					const waitTime = parseInt(response);

					if (waitTime >= 0) {
						document.getElementById('iwc-start-description').innerHTML =
							document.getElementById('iwc_ChatWaitTime').value.replace('##time##',
								waitTime <= 60
									? '1 minute'
									: Math.ceil(waitTime / 60.0).toString() + ' minutes');
						// ML added	
						updateWaitText(waitTime);
						startTimeCountDown(waitTime);
					} else {
						logError('Chat: failed getting wait time', { 'Error': JSON.stringify(response) });
						displayChatStartError(btn);
					}
				}).catch(function(error) {
					let waitEl = document.getElementById('iwc-startWaiting');
					if(waitEl) {
						waitEl.classList.remove('hide');
					}
					
					logError('Chat: failed getting wait time', { 'Error': JSON.stringify(error) });
				});
			}

			return {
				SaveSurvey: saveSurvey,
				GetProductInfo: getProductInfo,
				GetWaitTime: getWaitTime
			};			
		})();

		// END Ajax actions

		return {
			Init: init
		};
	} // End Chat4i
	chat4i().Init();
})()